import _options from "options";
import _util from "util";
import _url from "url";
import _querystring from "querystring";
import _events from "events";
import _sseclient from "./sseclient";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var Options = _options,
    util = _util,
    url = _url,
    querystring = _querystring,
    events = _events,
    SSEClient = _sseclient;

RegExp.quote = function (str) {
  return (str + "").replace(/[.?*+^$[\]\\(){}|-]/g, "\\$&");
};

exports = SSE;
exports.Client = SSEClient;

function SSE(httpServer, options) {
  options = new Options({
    path: "/sse",
    verifyRequest: null
  }).merge(options);
  (this || _global).server = httpServer;

  var oldListeners = (this || _global).server.listeners("request");

  (this || _global).server.removeAllListeners("request");

  var self = this || _global;

  (this || _global).server.on("request", function (req, res) {
    var u = url.parse(req.url);
    var pathname = u.pathname.replace(/^\/{2,}/, "/");

    if (self.matchesPath(pathname, options.value.path) && (options.value.verifyRequest == null || options.value.verifyRequest(req))) {
      self.handleRequest(req, res, u.query);
    } else {
      for (var i = 0, l = oldListeners.length; i < l; ++i) {
        oldListeners[i].call(self.server, req, res);
      }
    }
  });
}

util.inherits(SSE, events.EventEmitter);

SSE.prototype.handleRequest = function (req, res, query) {
  var client = new SSEClient(req, res);
  client.initialize();
  this.emit("connection", client, querystring.parse(query));
};

SSE.prototype.matchesPath = function (queryPath, matchPath) {
  var match = RegExp.quote(matchPath).replace("\\*", ".*");
  return RegExp("^" + match + "$").test(queryPath);
};

export default exports;